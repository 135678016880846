import { createSlice } from "@reduxjs/toolkit";

import {
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  commonPaginationForPost,
  getAscArray,
  getCurrentClientId,
  getDescArray,
  getFilterValuesByKey,
  getLegendsExtraShortName,
  getMultiSelectFilterValueIntoString,
  getTreeNodeObj,
  numberFormatIntoShortForm,
} from "../../../components/common/Utils/utils";
import { getMultiSelectFilterValueForApi } from "./../../../components/common/Utils/utils";
import { gettingSmartFilterForOncologyDenial_Call } from "../OncologyDashboard/OncologyDashboardKpisSlice";
import { getClaimAllFiltersData_Call } from "../DenialDetails/claimAllFiltersSlice";
import store from "../../store";

// carcHandleYearOverYear
let constDate = null;

const LineChartDataManipulation = (data) => {
  try {
    if (constDate === "1Y") {
      // Filter out entries with empty groupAndReasonCode
      const filteredData = data.filter(
        (entry) => entry.groupAndReasonCode.trim() !== ""
      );

      if (filteredData?.length) {
        // Organize data into a dictionary based on groupAndReasonCode
        const groupedData = filteredData.reduce((acc, entry) => {
          const key = entry.groupAndReasonCode;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(entry);
          return acc;
        }, {});

        // Create separate arrays for each group with entries for each month
        const resultArrays = Object.keys(groupedData).map(
          (groupAndReasonCode) => {
            const newArray = Array.from({ length: 12 }, (_, monthIndex) => {
              const monthName = new Date(2000, monthIndex, 1).toLocaleString(
                "en-US",
                { month: "long" }
              );
              const monthEntry =
                groupedData[groupAndReasonCode].find(
                  (entry) =>
                    entry.monthOfYear.toLowerCase() === monthName.toLowerCase()
                ) || {};

              return {
                groupAndReasonCode,
                month: monthName,
                reasonCode: monthEntry.reasonCode || "",
                description: monthEntry?.description || "",
                sumAmount:
                  parseInt(monthEntry?.denialPercentageReasonCode, 10) || 0,
                sumChargeAmount: parseInt(monthEntry.sumChargeAmount, 10) || 0,
                sumReasonCount: parseInt(monthEntry.sumReasonCount, 10) || 0,
                sumAmount1: parseInt(monthEntry.sumAmount, 10) || 0,
              };
            });

            return newArray;
          }
        );

        // Now resultArrays will contain separate arrays for each group with entries for each month
        const resultObjects = resultArrays.map((arr, index) => {
          const groupAndReasonCode = Object.keys(groupedData)[index];
          return {
            [groupAndReasonCode]: arr,
          };
        });

        return { resultObjects: resultObjects, graphFilter: constDate };
      } else {
        return { resultObjects: [], graphFilter: constDate };
      }
    } else if (constDate === "1M") {
      // Filter out entries with empty groupAndReasonCode
      const filteredData = data.filter(
        (entry) => entry.groupAndReasonCode.trim() !== ""
      );

      if (filteredData && filteredData?.length) {
        // Create a dictionary to store data based on groupAndReasonCode
        const groupedData = {};

        // Iterate through the data and organize it
        filteredData.forEach((entry, i) => {
          const { groupAndReasonCode, weekOfYear } = entry;

          // Create an array for the groupAndReasonCode if it doesn't exist
          if (!groupedData[groupAndReasonCode]) {
            groupedData[groupAndReasonCode] = [];
          }

          // Push the entry to the corresponding groupAndReasonCode array
          groupedData[groupAndReasonCode].push({
            groupAndReasonCode,
            weekOfYear,
            description: entry.description || "",
            reasonCode: entry.reasonCode || "",
            sumAmount: entry.denialPercentageReasonCode || "",
            sumChargeAmount: entry.sumChargeAmount || "",
            sumReasonCount: entry.sumReasonCount || "",
            sumAmount1: entry.sumAmount || "",
          });
        });

        // Determine the range of weeks
        const allWeeks = Array.from(
          new Set(filteredData.map((entry) => entry.weekOfYear))
        );

        // Convert the dictionary to an array of arrays with consistent lengths
        const resultArrays = Object.keys(groupedData).map((key) => {
          const groupArray = groupedData[key];

          // Fill in the gaps for each week
          const filledArray = allWeeks.map((week, i) => {
            const weekEntry = groupArray.find(
              (entry) => entry.weekOfYear === week
            ) || {
              groupAndReasonCode: key,
              weekOfYear: week,
              description: "",
              reasonCode: "",
              sumAmount: "0",
              sumChargeAmount: "0",
              sumReasonCount: "0",
              sumAmount1: "0",
            };

            return weekEntry;
          });

          // Sort the array based on weekOfYear
          const sortedArray = filledArray.sort(
            (a, b) => a.weekOfYear - b.weekOfYear
          );

          return sortedArray;

          // return filledArray;
        });

        // Now resultArrays will contain separate arrays for each group with entries for each month
        const resultObjects = resultArrays.map((arr, index) => {
          const groupAndReasonCode = Object.keys(groupedData)[index];
          return {
            [groupAndReasonCode]: arr,
          };
        });

        return { resultObjects: resultObjects, graphFilter: constDate };
      }
    } else {
      return { resultObjects: [], graphFilter: constDate };
    }
  } catch (e) {
    // console.log("e--->>", e);
  }
};

export const ExecutiveDashKpisSlice = createSlice({
  name: "ExecutiveDashKpisSlice",
  initialState: {
    executiveDashPayerArr: [],

    // payer dash stuff here
    payerFilterArr: [],
    payersDenialCard: {},
    payersTopCardObj: [],
    payersTopCardObjLoader: true,
    payersRevenueCard: {},
    payersTimeLineArr: [],
    payerDashboardAllCodeTimelineAmountArr1: [],
    payerDashboardAllCodeTimelineAmountArr1Loader: true,
    enabledGraphLabels: [],
    payerDashboardPayerInfo: [],
    payerDashboardPayerInfoLoader: true,
    denialByPayerTimeLineArr: [],
    denialPayersBarChartArr: [],
    denialPayersPieChartArr: [],
    payersBarChartArr: [],
    payerPlotGraphArr: [],

    departmentsTimeLineArr: [],
    departmentsDashboardAllCodeTimelineAmountArr1: [],
    departmentsDashboardAllCodeTimelineAmountArr1Loader: true,
    departmentsEnabledGraphLabels: [],
    departmentsTopCardObj: [],
    departmentsTopCardObjLoader: true,
    departmentDashboardPayerInfo: [],
    departmentDashboardPayerInfoLoader: true,
    departmentBarChartArr: [],
    departmentPlotGraphArr: [],
    departmentDenialPayersPieChartArr: [],

    // reasonCodeTrendsByPayer dashboard code start here

    reasonCodeTrendsPayerGraphArr: [],

    reasonCodeTrendsPayerFilterArr: [],
    reasonCodeTrendsGroupReasonCodeFilterArr: [],
    reasonCodeTrendsFilter: {
      loadingLineChart: true,
      loadingPayerFilter: true,
      loadingGroupReasonCodeFilter: true,
    },
  },

  reducers: {
    ExecutivePayerData: (state, action) => {
      let data = action.payload;
      state.executiveDashPayerArr = data;
    },

    setPayerDashNewTopCards: (state, action) => {
      let data = action.payload;

      if (data?.payersTopCard?.length) {
        state.payersTopCardObj = data?.payersTopCard;
      } else {
        state.payersTopCardObj = [];
      }

      if (data?.payerFilter?.length) {
        // removing duplicate payerName here start
        const uniquePayerNames = {};
        const uniqueArrayOfObjects = data?.payerFilter.filter((obj) => {
          if (!uniquePayerNames[obj.payerName]) {
            uniquePayerNames[obj.payerName] = true;
            return true;
          }
          return false;
        });
        // removing duplicate payerName here end

        // Sort the array alphabetically based on payerName
        uniqueArrayOfObjects.sort((a, b) => {
          const payerNameA = a.payerName.toLowerCase();
          const payerNameB = b.payerName.toLowerCase();
          if (payerNameA < payerNameB) return -1;
          if (payerNameA > payerNameB) return 1;
          return 0;
        });

        let newArr = [];
        uniqueArrayOfObjects?.forEach((item) => {
          newArr.push({
            label: item?.payerName,
            value: item?.payerId,
            // payerId: item?.payerId,
          });
        });

        state.payerFilterArr = newArr;
      } else {
        state.payerFilterArr = [];
      }

      state.payersTopCardObjLoader = false;
    },

    setPayerDashTopFive: (state, action) => {
      let data = action.payload;
      try {
        // console.log("setPayerDashTopFive_data--->>", data);
        if (data?.length) {
          let newArr1 = [];
          let newArrWithoutAmount = [];

          const weekOne = data?.filter((item) =>
            item.weekNo ? item.weekNo === 1 : item?.monthNo === 1
          );
          const weekTwo = data?.filter((item) =>
            item.weekNo ? item.weekNo === 2 : item?.monthNo === 2
          );
          const weekThree = data?.filter((item) =>
            item.weekNo ? item.weekNo === 3 : item?.monthNo === 3
          );
          const weekFour = data?.filter((item) =>
            item.weekNo ? item.weekNo === 4 : item?.monthNo === 4
          );
          const weekFive = data?.filter((item) =>
            item.weekNo ? item.weekNo === 5 : item?.monthNo === 5
          );
          const weekSix = data?.filter((item) =>
            item.weekNo ? item.weekNo === 6 : item?.monthNo === 6
          );
          const weekSeven = data?.filter((item) => item?.monthNo === 7);
          const weekEight = data?.filter((item) => item?.monthNo === 8);
          const weekNine = data?.filter((item) => item?.monthNo === 9);
          const weekTen = data?.filter((item) => item?.monthNo === 10);
          const weekEleven = data?.filter((item) => item?.monthNo === 11);
          const weekTwelve = data?.filter((item) => item?.monthNo === 12);
          let payerNames = [
            ...new Set(
              data?.map((item) => {
                return item.payerName;
              })
            ),
          ];

          let weeksData = [];
          if (weekSeven.length > 0) {
            weeksData = [
              weekOne,
              weekTwo,
              weekThree,
              weekFour,
              weekFive,
              weekSix,
              weekSeven,
              weekEight,
              weekNine,
              weekTen,
              weekEleven,
              weekTwelve,
            ];

            for (let i = 0; i < weeksData.length; i++) {
              if (weeksData?.[i]?.length) {
                newArr1.push({
                  name: `${
                    data?.[0]?.weekNo
                      ? `Week ${i + 1}`
                      : weeksData?.[i]?.[0]?.monthName
                  }`,
                  [payerNames[0]]: weeksData?.[i]?.[0]?.softDenialPercentage,
                  [payerNames[1]]: weeksData?.[i]?.[1]?.softDenialPercentage,
                  [payerNames[2]]: weeksData?.[i]?.[2]?.softDenialPercentage,
                  [payerNames[3]]: weeksData?.[i]?.[3]?.softDenialPercentage,
                  [payerNames[4]]: weeksData?.[i]?.[4]?.softDenialPercentage,
                  [payerNames[5]]: weeksData?.[i]?.[5]?.softDenialPercentage,
                  [payerNames[6]]: weeksData?.[i]?.[6]?.softDenialPercentage,
                  [payerNames[7]]: weeksData?.[i]?.[7]?.softDenialPercentage,
                  [payerNames[8]]: weeksData?.[i]?.[8]?.softDenialPercentage,
                  [payerNames[9]]: weeksData?.[i]?.[9]?.softDenialPercentage,
                  [payerNames[10]]: weeksData?.[i]?.[10]?.softDenialPercentage,
                  [payerNames[11]]: weeksData?.[i]?.[11]?.softDenialPercentage,
                  [`${payerNames[0]}Amount`]:
                    weeksData?.[i]?.[0]?.totalSoftDenial,
                  [`${payerNames[1]}Amount`]:
                    weeksData?.[i]?.[1]?.totalSoftDenial,
                  [`${payerNames[2]}Amount`]:
                    weeksData?.[i]?.[2]?.totalSoftDenial,
                  [`${payerNames[3]}Amount`]:
                    weeksData?.[i]?.[3]?.totalSoftDenial,
                  [`${payerNames[4]}Amount`]:
                    weeksData?.[i]?.[4]?.totalSoftDenial,
                  [`${payerNames[5]}Amount`]:
                    weeksData?.[i]?.[5]?.totalSoftDenial,
                  [`${payerNames[6]}Amount`]:
                    weeksData?.[i]?.[6]?.totalSoftDenial,
                  [`${payerNames[7]}Amount`]:
                    weeksData?.[i]?.[7]?.totalSoftDenial,
                  [`${payerNames[8]}Amount`]:
                    weeksData?.[i]?.[8]?.totalSoftDenial,
                  [`${payerNames[9]}Amount`]:
                    weeksData?.[i]?.[9]?.totalSoftDenial,
                  [`${payerNames[10]}Amount`]:
                    weeksData?.[i]?.[10]?.totalSoftDenial,
                  [`${payerNames[11]}Amount`]:
                    weeksData?.[i]?.[11]?.totalSoftDenial,

                  // [`${payerNames[0]}Count`]: weeksData?.[i]?.[0]?.claimCount,
                  // [`${payerNames[1]}Count`]: weeksData?.[i]?.[1]?.claimCount,
                  // [`${payerNames[2]}Count`]: weeksData?.[i]?.[2]?.claimCount,
                  // [`${payerNames[3]}Count`]: weeksData?.[i]?.[3]?.claimCount,
                  // [`${payerNames[4]}Count`]: weeksData?.[i]?.[4]?.claimCount,
                  // [`${payerNames[5]}Count`]: weeksData?.[i]?.[5]?.claimCount,
                  // [`${payerNames[6]}Count`]: weeksData?.[i]?.[6]?.claimCount,
                  // [`${payerNames[7]}Count`]: weeksData?.[i]?.[7]?.claimCount,
                  // [`${payerNames[8]}Count`]: weeksData?.[i]?.[8]?.claimCount,
                  // [`${payerNames[9]}Count`]: weeksData?.[i]?.[9]?.claimCount,
                  // [`${payerNames[10]}Count`]: weeksData?.[i]?.[10]?.claimCount,
                  // [`${payerNames[11]}Count`]: weeksData?.[i]?.[11]?.claimCount,

                  // type: "All",
                });

                newArrWithoutAmount.push({
                  name: `${
                    data?.[0]?.weekNo
                      ? `Week ${i + 1}`
                      : weeksData?.[i]?.[0]?.monthName
                  }`,
                  [payerNames[0]]: weeksData?.[i]?.[0]?.softDenialPercentage,
                  [payerNames[1]]: weeksData?.[i]?.[1]?.softDenialPercentage,
                  [payerNames[2]]: weeksData?.[i]?.[2]?.softDenialPercentage,
                  [payerNames[3]]: weeksData?.[i]?.[3]?.softDenialPercentage,
                  [payerNames[4]]: weeksData?.[i]?.[4]?.softDenialPercentage,
                  [payerNames[5]]: weeksData?.[i]?.[5]?.softDenialPercentage,
                  [payerNames[6]]: weeksData?.[i]?.[6]?.softDenialPercentage,
                  [payerNames[7]]: weeksData?.[i]?.[7]?.softDenialPercentage,
                  [payerNames[8]]: weeksData?.[i]?.[8]?.softDenialPercentage,
                  [payerNames[9]]: weeksData?.[i]?.[9]?.softDenialPercentage,
                  [payerNames[10]]: weeksData?.[i]?.[10]?.softDenialPercentage,
                  [payerNames[11]]: weeksData?.[i]?.[11]?.softDenialPercentage,

                  // type: "All",
                });
              }
            }
          } else {
            weeksData = [
              weekOne,
              weekTwo,
              weekThree,
              weekFour,
              weekFive,
              weekSix,
            ];
            for (let i = 0; i < 5; i++) {
              if (weeksData?.[i]?.length) {
                newArr1.push({
                  name: `${
                    data?.[0]?.weekNo
                      ? `Week ${i + 1}`
                      : weeksData?.[i]?.[0]?.monthName
                  }`,
                  [payerNames[0]]: weeksData?.[i]?.[0]?.softDenialPercentage,
                  [payerNames[1]]: weeksData?.[i]?.[1]?.softDenialPercentage,
                  [payerNames[2]]: weeksData?.[i]?.[2]?.softDenialPercentage,
                  [payerNames[3]]: weeksData?.[i]?.[3]?.softDenialPercentage,
                  [payerNames[4]]: weeksData?.[i]?.[4]?.softDenialPercentage,
                  // [payerNames[5]]: weeksData?.[i]?.[5]?.softDenialPercentage,
                  [`${payerNames[0]}Amount`]:
                    weeksData?.[i]?.[0]?.totalSoftDenial,
                  [`${payerNames[1]}Amount`]:
                    weeksData?.[i]?.[1]?.totalSoftDenial,
                  [`${payerNames[2]}Amount`]:
                    weeksData?.[i]?.[2]?.totalSoftDenial,
                  [`${payerNames[3]}Amount`]:
                    weeksData?.[i]?.[3]?.totalSoftDenial,
                  [`${payerNames[4]}Amount`]:
                    weeksData?.[i]?.[4]?.totalSoftDenial,

                  // [`${payerNames[0]}Count`]: weeksData?.[i]?.[0]?.claimCount,
                  // [`${payerNames[1]}Count`]: weeksData?.[i]?.[1]?.claimCount,
                  // [`${payerNames[2]}Count`]: weeksData?.[i]?.[2]?.claimCount,
                  // [`${payerNames[3]}Count`]: weeksData?.[i]?.[3]?.claimCount,
                  // [`${payerNames[4]}Count`]: weeksData?.[i]?.[4]?.claimCount,
                  // [`${payerNames[5]}Amount`]:
                  //   weeksData?.[i]?.[5]?.totalSoftDenial,
                  // type: "All",
                });
                newArrWithoutAmount.push({
                  name: `${
                    data?.[0]?.weekNo
                      ? `Week ${i + 1}`
                      : weeksData?.[i]?.[0]?.monthName
                  }`,
                  [payerNames[0]]: weeksData?.[i]?.[0]?.softDenialPercentage,
                  [payerNames[1]]: weeksData?.[i]?.[1]?.softDenialPercentage,
                  [payerNames[2]]: weeksData?.[i]?.[2]?.softDenialPercentage,
                  [payerNames[3]]: weeksData?.[i]?.[3]?.softDenialPercentage,
                  [payerNames[4]]: weeksData?.[i]?.[4]?.softDenialPercentage,
                });
              }
            }
          }

          // Calculate average values for each key
          const averages = {};

          const keys = Object.keys(newArrWithoutAmount[0]).filter(
            (key) => key !== "name"
          );
          const fileterdKeys = keys.filter((item) => {
            return item !== "undefined";
          });

          keys.forEach((key) => {
            const sum = newArrWithoutAmount.reduce((acc, obj) => {
              const value = obj[key];
              if (!isNaN(value)) {
                return acc + value;
              } else {
                // console.log(
                //   `123123 Invalid value for key ${key} in object ${
                //     obj.name
                //   }: ${value} - ${Object.keys(obj)}`
                // );
                return acc;
              }
            }, 0);
            averages[key] = sum / newArrWithoutAmount.length;
          });

          // Find the highest average value
          const highestAverage = Math.max(...Object.values(averages)).toFixed(
            1
          );
          let resultArray = [];

          for (let k = 0; k < Object.values(averages).length; k++) {
            Object.values(averages)?.[k].toFixed(1) >= highestAverage
              ? resultArray.push(true)
              : resultArray.push(false);
          }
          // console.log(
          //   "123123 ---> ",
          //   resultArray,
          //   Object.values(averages),
          //   highestAverage
          // );

          // Generate new array based on highest average value
          // const resultArray = newArrWithoutAmount.map((obj) => {
          //   const avg = calculateAverage(obj).toFixed(2);
          //   return avg >= highestAverage;
          // });

          state.payersTimeLineArr = payerNames;
          // console.log("123123 data made - ", newArr1);
          state.payerDashboardAllCodeTimelineAmountArr1 = newArr1;
          state.enabledGraphLabels = resultArray;
        } else {
          state.payersTimeLineArr = [];
          state.payerDashboardAllCodeTimelineAmountArr1 = [];
        }
        state.payerDashboardAllCodeTimelineAmountArr1Loader = false;
      } catch (error) {
        state.payerDashboardAllCodeTimelineAmountArr1Loader = false;
      }
    },

    setPayerDashTopTen: (state, action) => {
      try {
        let data = action.payload;
        // console.log(
        //   "123123 LLLLL --- ",
        //   data.sort(
        //     (a, b) =>
        //       parseFloat(b.totalSoftDenial) - parseFloat(a.totalSoftDenial)
        //   )
        // );
        if (data?.length > 0) {
          // Create a shallow copy of the array
          const dataCopy = [...data];

          // Sort the copy
          dataCopy.sort(
            (a, b) =>
              parseFloat(b.totalSoftDenial) - parseFloat(a.totalSoftDenial)
          );

          // Use the sorted copy as needed
          // For example, set it back to your state or use it in further processing

          state.payerDashboardPayerInfo = dataCopy;
        } else {
          // console.log("123123 else --- ", data);

          state.payerDashboardPayerInfo = [];
        }

        if (data && data?.length) {
          let amountArr = [];
          let newArr = [];
          let arrForPlotChart = [];
          data?.forEach((item) => {
            // Bar chart values
            amountArr.push({
              country: getLegendsExtraShortName(item?.payerName),
              "Charge Amount": item?.totalChargeAmount,
              "Denial Amount": item?.totalSoftDenial,
              denialCount: item?.softServiceDenialCount,
              denialWeitage: item?.softDenialPercentage,
              totalCount: item?.paymentCount,
              payerName: item?.payerName,
            });

            // Pie chart values
            newArr.push({
              id: getLegendsExtraShortName(item?.payerName),
              label: getLegendsExtraShortName(item?.payerName),
              value: item?.softDenialWeightage,
              name: item?.payerName,
            });

            //Plot chart Values
            arrForPlotChart.push({
              name: getLegendsExtraShortName(item?.payerName),
              "Charge Amount": item?.totalChargeAmount,
              "Denial Amount": item?.totalSoftDenial,
              // pv: item?.totalChargeAmount,
              fullName: item?.payerName,
            });
          });

          // let asc_amountArr = getAscArray(amountArr, "Denial Amount");

          // let dec_chargeAmountArr = getDescArray(
          //   arrForPlotChart,
          //   "Soft Denial"
          // );

          // state.payersBarChartArr = asc_amountArr;
          // console.log(">> data amountArr", amountArr);
          // console.log(">> data newArr", newArr);
          state.payersBarChartArr = amountArr;
          state.denialPayersPieChartArr = newArr;
          // state.payerPlotGraphArr = dec_chargeAmountArr;
          state.payerPlotGraphArr = arrForPlotChart;
        } else {
          state.payersBarChartArr = [];
          state.denialPayersPieChartArr = [];
          state.payerPlotGraphArr = [];
        }

        state.payerDashboardPayerInfoLoader = false;
      } catch (error) {
        console.log("error2--- >>", error);
        state.payerDashboardPayerInfoLoader = false;
      }
    },

    setDenialByPayerValues: (state, action) => {
      let data = action.payload;

      //handle graph data
      if (data && data?.denialTimeLine && data?.denialTimeLine?.length) {
        let res = data?.denialTimeLine.map((item, index) => {
          return {
            "gained > 100$": item?.paymentAmount,
            // "gained <= 100$": gain,
            "lost <= 100$": -item?.denialAmount,
            // "lost > 100$": -highLoss,
            user: getLegendsExtraShortName(item?.payerName),
            item: item,
          };
        });
        state.denialByPayerTimeLineArr = res;
      } else {
        state.denialByPayerTimeLineArr = [];
      }

      // denialByPayer handle here
      if (data && data?.denialPayers && data?.denialPayers?.length) {
        let amountArr = [];
        let newArr = [];
        data?.denialPayers.forEach((item) => {
          // Bar chart values
          amountArr.push({
            country: getLegendsExtraShortName(item?.payerName),
            "Charge Amount": item?.chargeAmount,
            "Denial Amount": item?.softAdjustment,
            denialCount: item?.softAdjustmentCount,
            denialWeitage: item?.softDenialWeightage,
            totalCount: item?.totalCount,
            payerName: item?.payerName,
          });

          // Pie chart values
          newArr.push({
            id: getLegendsExtraShortName(item?.payerName),
            label: getLegendsExtraShortName(item?.payerName),
            value: item?.softDenialWeightage,
            name: item?.payerName,
          });
        });

        let asc_amountArr = getAscArray(amountArr, "Denial Amount");

        state.denialPayersBarChartArr = asc_amountArr;
        state.denialPayersPieChartArr = newArr;
      } else {
        state.denialPayersBarChartArr = [];
        state.denialPayersPieChartArr = [];
      }
    },

    setDepartmentDashTopFive: (state, action) => {
      let data = action.payload;
      try {
        // console.log("123123 weeks ", data);

        // const resultArr = transformDataForEqualWeightDenial(
        //   data,
        //   "level0Name",
        //   0
        // );

        //         extraLegend: []
        // finalArr: []
        // keyValues: []

        // console.log("123123 weeks ", resultArr);
        if (data?.length > 0) {
          let newArr1 = [];
          let newArrWithoutAmount = [];

          const weekOne = data?.filter((item) => item.dateRange == 1);
          const weekTwo = data?.filter((item) => item.dateRange == 2);
          const weekThree = data?.filter((item) => item.dateRange == 3);
          const weekFour = data?.filter((item) => item.dateRange == 4);
          const weekFive = data?.filter((item) => item.dateRange == 5);
          const weekSix = data?.filter((item) => item.dateRange == 6);
          const weekSeven = data?.filter((item) => item?.dateRange == 7);
          const weekEight = data?.filter((item) => item?.dateRange == 8);
          const weekNine = data?.filter((item) => item?.dateRange == 9);
          const weekTen = data?.filter((item) => item?.dateRange == 10);
          const weekEleven = data?.filter((item) => item?.dateRange == 11);
          const weekTwelve = data?.filter((item) => item?.dateRange == 12);
          let payerNames = [
            ...new Set(
              data?.map((item) => {
                return item.level0Name;
              })
            ),
          ];

          let weeksData = [];
          if (weekSeven.length > 0) {
            weeksData = [
              weekOne,
              weekTwo,
              weekThree,
              weekFour,
              weekFive,
              weekSix,
              weekSeven,
              weekEight,
              weekNine,
              weekTen,
              weekEleven,
              weekTwelve,
            ];

            for (let i = 0; i < weeksData.length; i++) {
              if (weeksData?.[i]?.length) {
                newArr1.push({
                  name: `${
                    // data?.[0]?.weekNo
                    //   ?
                    //   `Week ${i + 1}`
                    //   :
                    weeksData?.[i]?.[0]?.monthName
                  }`,
                  [payerNames[0]]: weeksData?.[i]?.[0]?.softDenialPercentage,
                  [payerNames[1]]: weeksData?.[i]?.[1]?.softDenialPercentage,
                  [payerNames[2]]: weeksData?.[i]?.[2]?.softDenialPercentage,
                  [payerNames[3]]: weeksData?.[i]?.[3]?.softDenialPercentage,
                  [payerNames[4]]: weeksData?.[i]?.[4]?.softDenialPercentage,
                  [payerNames[5]]: weeksData?.[i]?.[5]?.softDenialPercentage,
                  [payerNames[6]]: weeksData?.[i]?.[6]?.softDenialPercentage,
                  [payerNames[7]]: weeksData?.[i]?.[7]?.softDenialPercentage,
                  [payerNames[8]]: weeksData?.[i]?.[8]?.softDenialPercentage,
                  [payerNames[9]]: weeksData?.[i]?.[9]?.softDenialPercentage,
                  [payerNames[10]]: weeksData?.[i]?.[10]?.softDenialPercentage,
                  [payerNames[11]]: weeksData?.[i]?.[11]?.softDenialPercentage,
                  [`${payerNames[0]}Amount`]:
                    weeksData?.[i]?.[0]?.softDenialAmount,
                  [`${payerNames[1]}Amount`]:
                    weeksData?.[i]?.[1]?.softDenialAmount,
                  [`${payerNames[2]}Amount`]:
                    weeksData?.[i]?.[2]?.softDenialAmount,
                  [`${payerNames[3]}Amount`]:
                    weeksData?.[i]?.[3]?.softDenialAmount,
                  [`${payerNames[4]}Amount`]:
                    weeksData?.[i]?.[4]?.softDenialAmount,
                  [`${payerNames[5]}Amount`]:
                    weeksData?.[i]?.[5]?.softDenialAmount,
                  [`${payerNames[6]}Amount`]:
                    weeksData?.[i]?.[6]?.softDenialAmount,
                  [`${payerNames[7]}Amount`]:
                    weeksData?.[i]?.[7]?.softDenialAmount,
                  [`${payerNames[8]}Amount`]:
                    weeksData?.[i]?.[8]?.softDenialAmount,
                  [`${payerNames[9]}Amount`]:
                    weeksData?.[i]?.[9]?.softDenialAmount,
                  [`${payerNames[10]}Amount`]:
                    weeksData?.[i]?.[10]?.softDenialAmount,
                  [`${payerNames[11]}Amount`]:
                    weeksData?.[i]?.[11]?.softDenialAmount,

                  [`${payerNames[0]}Count`]: weeksData?.[i]?.[0]?.claimCount,
                  [`${payerNames[1]}Count`]: weeksData?.[i]?.[1]?.claimCount,
                  [`${payerNames[2]}Count`]: weeksData?.[i]?.[2]?.claimCount,
                  [`${payerNames[3]}Count`]: weeksData?.[i]?.[3]?.claimCount,
                  [`${payerNames[4]}Count`]: weeksData?.[i]?.[4]?.claimCount,
                  [`${payerNames[5]}Count`]: weeksData?.[i]?.[5]?.claimCount,
                  [`${payerNames[6]}Count`]: weeksData?.[i]?.[6]?.claimCount,
                  [`${payerNames[7]}Count`]: weeksData?.[i]?.[7]?.claimCount,
                  [`${payerNames[8]}Count`]: weeksData?.[i]?.[8]?.claimCount,
                  [`${payerNames[9]}Count`]: weeksData?.[i]?.[9]?.claimCount,
                  [`${payerNames[10]}Count`]: weeksData?.[i]?.[10]?.claimCount,
                  [`${payerNames[11]}Count`]: weeksData?.[i]?.[11]?.claimCount,

                  // type: "All",
                });

                newArrWithoutAmount.push({
                  name: `${
                    // data?.[0]?.weekNo
                    //   ? `Week ${i + 1}`
                    //   :
                    weeksData?.[i]?.[0]?.monthName
                  }`,
                  [payerNames[0]]: weeksData?.[i]?.[0]?.softDenialPercentage,
                  [payerNames[1]]: weeksData?.[i]?.[1]?.softDenialPercentage,
                  [payerNames[2]]: weeksData?.[i]?.[2]?.softDenialPercentage,
                  [payerNames[3]]: weeksData?.[i]?.[3]?.softDenialPercentage,
                  [payerNames[4]]: weeksData?.[i]?.[4]?.softDenialPercentage,
                  [payerNames[5]]: weeksData?.[i]?.[5]?.softDenialPercentage,
                  [payerNames[6]]: weeksData?.[i]?.[6]?.softDenialPercentage,
                  [payerNames[7]]: weeksData?.[i]?.[7]?.softDenialPercentage,
                  [payerNames[8]]: weeksData?.[i]?.[8]?.softDenialPercentage,
                  [payerNames[9]]: weeksData?.[i]?.[9]?.softDenialPercentage,
                  [payerNames[10]]: weeksData?.[i]?.[10]?.softDenialPercentage,
                  [payerNames[11]]: weeksData?.[i]?.[11]?.softDenialPercentage,

                  // type: "All",
                });
              }
            }
          } else {
            weeksData = [
              weekOne,
              weekTwo,
              weekThree,
              weekFour,
              weekFive,
              weekSix,
            ];
            for (let i = 0; i < 5; i++) {
              if (weeksData?.[i]?.length) {
                newArr1.push({
                  name: `${
                    // data?.[0]?.date
                    //   ?
                    `Week ${i + 1}`
                    // : weeksData?.[i]?.[0]?.monthName
                  }`,
                  [payerNames[0]]: weeksData?.[i]?.[0]?.softDenialPercentage,
                  [payerNames[1]]: weeksData?.[i]?.[1]?.softDenialPercentage,
                  [payerNames[2]]: weeksData?.[i]?.[2]?.softDenialPercentage,
                  [payerNames[3]]: weeksData?.[i]?.[3]?.softDenialPercentage,
                  [payerNames[4]]: weeksData?.[i]?.[4]?.softDenialPercentage,
                  // [payerNames[5]]: weeksData?.[i]?.[5]?.softDenialPercentage,
                  [`${payerNames[0]}Amount`]:
                    weeksData?.[i]?.[0]?.softDenialAmount,
                  [`${payerNames[1]}Amount`]:
                    weeksData?.[i]?.[1]?.softDenialAmount,
                  [`${payerNames[2]}Amount`]:
                    weeksData?.[i]?.[2]?.softDenialAmount,
                  [`${payerNames[3]}Amount`]:
                    weeksData?.[i]?.[3]?.softDenialAmount,
                  [`${payerNames[4]}Amount`]:
                    weeksData?.[i]?.[4]?.softDenialAmount,

                  [`${payerNames[0]}Count`]: weeksData?.[i]?.[0]?.claimCount,
                  [`${payerNames[1]}Count`]: weeksData?.[i]?.[1]?.claimCount,
                  [`${payerNames[2]}Count`]: weeksData?.[i]?.[2]?.claimCount,
                  [`${payerNames[3]}Count`]: weeksData?.[i]?.[3]?.claimCount,
                  [`${payerNames[4]}Count`]: weeksData?.[i]?.[4]?.claimCount,
                  // [`${payerNames[5]}Amount`]:
                  //   weeksData?.[i]?.[5]?.totalSoftDenial,
                  // type: "All",
                });
                newArrWithoutAmount.push({
                  name: `${
                    // data?.[0]?.weekNo
                    //   ?
                    `Week ${i + 1}`
                    // : weeksData?.[i]?.[0]?.monthName
                  }`,
                  [payerNames[0]]: weeksData?.[i]?.[0]?.softDenialPercentage,
                  [payerNames[1]]: weeksData?.[i]?.[1]?.softDenialPercentage,
                  [payerNames[2]]: weeksData?.[i]?.[2]?.softDenialPercentage,
                  [payerNames[3]]: weeksData?.[i]?.[3]?.softDenialPercentage,
                  [payerNames[4]]: weeksData?.[i]?.[4]?.softDenialPercentage,
                });
              }
            }
          }

          // Calculate average values for each key
          const averages = {};

          const keys = Object.keys(newArrWithoutAmount[0]).filter(
            (key) => key !== "name"
          );
          const fileterdKeys = keys.filter((item) => {
            return item !== "undefined";
          });
          // console.log("123123 keys - ", fileterdKeys);

          keys.forEach((key) => {
            const sum = newArrWithoutAmount.reduce((acc, obj) => {
              const value = obj[key];
              if (!isNaN(value)) {
                return acc + value;
              } else {
                // console.log(
                //   `123123 Invalid value for key ${key} in object ${
                //     obj.name
                //   }: ${value} - ${Object.keys(obj)}`
                // );
                return acc;
              }
            }, 0);
            averages[key] = sum / newArrWithoutAmount.length;
          });

          // Find the highest average value
          const highestAverage = Math.max(...Object.values(averages)).toFixed(
            1
          );
          let resultArray = [];

          for (let k = 0; k < Object.values(averages).length; k++) {
            Object.values(averages)?.[k].toFixed(1) >= highestAverage
              ? resultArray.push(true)
              : resultArray.push(false);
          }
          // console.log(
          //   "123123 ---> ",
          //   resultArray,
          //   Object.values(averages),
          //   highestAverage
          // );

          // Generate new array based on highest average value
          // const resultArray = newArrWithoutAmount.map((obj) => {
          //   const avg = calculateAverage(obj).toFixed(2);
          //   return avg >= highestAverage;
          // });

          state.departmentsTimeLineArr = payerNames;
          // console.log("123123 data made - ", newArr1);
          state.departmentsDashboardAllCodeTimelineAmountArr1 = newArr1;
          state.departmentsEnabledGraphLabels = resultArray;
        } else {
          state.departmentsTimeLineArr = [];
          state.departmentsDashboardAllCodeTimelineAmountArr1 = [];
          state.departmentsEnabledGraphLabels = [];
        }
        state.departmentsDashboardAllCodeTimelineAmountArr1Loader = false;
      } catch (error) {
        // console.log("123123 > ", error);
      }
    },
    setDepartmnetDashTopTen: (state, action) => {
      try {
        let data = action.payload;
        // console.log(">> LLLLL --- ", data);
        if (data?.length > 0) {
          state.departmentDashboardPayerInfo = data;
        } else {
          state.departmentDashboardPayerInfo = [];
        }

        if (data && data?.length > 0) {
          let amountArr = [];
          let newArr = [];
          let arrForPlotChart = [];
          data.forEach((item) => {
            // Bar chart values
            amountArr.push({
              country: getLegendsExtraShortName(item?.level0Name),
              "Charge Amount": item?.chargeAmount,
              "Denial Amount": item?.softDenialAmount,
              denialCount: item?.softServiceDenialCount,
              denialWeitage: item?.softDenialPercentage,
              totalCount: item?.paymentCount,
              payerName: item?.level0Name,
            });

            // Pie chart values
            newArr.push({
              id: getLegendsExtraShortName(item?.level0Name),
              label: getLegendsExtraShortName(item?.level0Name),
              value: item?.softDenialPercentage,
              name: item?.level0Name,
            });

            //Plot chart Values
            arrForPlotChart.push({
              name: getLegendsExtraShortName(item?.level0Name),
              "Charge Amount": item?.chargeAmount,
              "Denial Amount": item?.softDenialAmount,
              // pv: item?.totalChargeAmount,
              fullName: item?.level0Name,
            });
          });

          // let asc_amountArr = getAscArray(amountArr, "Denial Amount");

          // let dec_chargeAmountArr = getDescArray(
          //   arrForPlotChart,
          //   "Soft Denial"
          // );

          // state.departmentBarChartArr = asc_amountArr;
          state.departmentBarChartArr = amountArr;
          state.departmentDenialPayersPieChartArr = newArr;
          state.departmentPlotGraphArr = arrForPlotChart;
          // state.departmentPlotGraphArr = dec_chargeAmountArr;
        } else {
          state.departmentBarChartArr = [];
          state.departmentDenialPayersPieChartArr = [];
          state.departmentPlotGraphArr = [];
        }

        state.departmentDashboardPayerInfoLoader = false;
      } catch (error) {
        // console.log("error--- >>", error);
      }
    },

    setDepartmentDashNewTopCards: (state, action) => {
      let data = action.payload;
      // console.log("setting >> ", data);
      if (data?.departmentTopCard) {
        state.departmentsTopCardObj = data?.departmentTopCard;
      } else {
        state.departmentsTopCardObj = [];
      }
      state.departmentsTopCardObjLoader = false;
    },
    setDepartmentsDashboardLoaders: (state, action) => {
      let data = action.payload;

      state.departmentsTopCardObjLoader = data;
      state.departmentDashboardPayerInfoLoader = data;
      state.departmentsDashboardAllCodeTimelineAmountArr1Loader = data;
    },

    setPayersDashboardLoaders: (state, action) => {
      let data = action.payload;

      state.payersTopCardObjLoader = data;
      state.payerDashboardPayerInfoLoader = data;
      state.payerDashboardAllCodeTimelineAmountArr1Loader = data;
    },

    // ==================== reasonCodeTrendsByPayer dashboard code start here ==================
    gettingPayerfilter_data: (state, action) => {
      state.reasonCodeTrendsFilter.loadingPayerFilter = true;
    },
    gettingGroupReasonCodefilter_data: (state, action) => {
      state.reasonCodeTrendsFilter.loadingGroupReasonCodeFilter = true;
    },
    setPayerfilter_data: (state, action) => {
      let data1 = action.payload;
      // console.log("data1--->>", data1);
      // denialsByFacility handle here
      if (data1 && data1?.length) {
        let newArr = [];
        // data1?.forEach((item) => {
        //   let desc = item?.Code + ` - ${item?.Description}`;
        //   newArr.push({
        //     label: desc,
        //     value: item?.Code,
        //   });
        // });

        state.reasonCodeTrendsPayerFilterArr = newArr;
      } else {
        state.reasonCodeTrendsPayerFilterArr = [];
      }
      state.reasonCodeTrendsFilter.loadingPayerFilter = false;
    },

    gettingLineChart: (state, action) => {
      state.reasonCodeTrendsFilter.loadingLineChart = true;
    },

    setLineChartData: (state, action) => {
      let data = action.payload;

      // payer filter handle here
      if (data?.payerTimelineFilter?.length) {
        let uniquePayerNames = new Set();
        let newArr = [];
        data?.payerTimelineFilter.forEach((item) => {
          if (!uniquePayerNames.has(item.payerName)) {
            newArr.push({
              label: item.payerName,
              value: item.payerId,
            });
            uniquePayerNames.add(item.payerName);
          }
        });

        state.reasonCodeTrendsPayerFilterArr = newArr;
        state.reasonCodeTrendsFilter.loadingPayerFilter = false;
      } else {
        state.reasonCodeTrendsPayerFilterArr = [];
        state.reasonCodeTrendsFilter.loadingPayerFilter = false;
      }

      // graph data handle here
      if (data?.groupCARCtimeline?.length) {
        let dataMani = LineChartDataManipulation(data?.groupCARCtimeline);
        state.reasonCodeTrendsPayerGraphArr = dataMani;
        state.reasonCodeTrendsFilter.loadingLineChart = false;
      } else {
        state.reasonCodeTrendsPayerGraphArr = [];
        state.reasonCodeTrendsFilter.loadingLineChart = false;
      }

      // group-reason code handle here
      if (data?.carcTimelineFilter?.length) {
        const filteredData = data.carcTimelineFilter.filter(
          (item) => item.groupAndReasonCode
        );
        const newArr = filteredData.map((item) => ({
          label: `${item.groupAndReasonCode} - ${item.description}`,
          value: item.groupAndReasonCode,
        }));

        state.reasonCodeTrendsGroupReasonCodeFilterArr = newArr;
        state.reasonCodeTrendsFilter.loadingGroupReasonCodeFilter = false;
      } else {
        state.reasonCodeTrendsGroupReasonCodeFilterArr = [];
        state.reasonCodeTrendsFilter.loadingGroupReasonCodeFilter = false;
      }
    },

    // setPayersFilters: (state, action) => {
    //   let data = action.payload;

    //   if (data?.timelineFilter?.length) {
    //     let newArr = [];
    //     data?.timelineFilter.forEach((item) => {
    //       newArr.push({
    //         label: item?.payerName,
    //         value: item?.payerId,
    //       });
    //     });

    //     state.reasonCodeTrendsPayerFilterArr = newArr;
    //     state.reasonCodeTrendsFilter.loadingPayerFilter = false;
    //   } else {
    //     state.reasonCodeTrendsPayerFilterArr = [];
    //     state.reasonCodeTrendsFilter.loadingPayerFilter = false;
    //   }
    // },

    // ==================== reasonCodeTrendsByPayer dashboard code end here ==================
  },
});

export const {
  ExecutivePayerData,
  setPayerDashTopFive,
  setDepartmentDashTopFive,
  setDepartmnetDashTopTen,
  setPayerDashTopTen,
  setPayerDashNewTopCards,
  setDepartmentDashNewTopCards,
  setDenialByPayerValues,
  setPayerfilter_data,
  gettingServiceLineFilter,
  gettingLineChart,
  setLineChartData,
  gettingPayerfilter_data,
  gettingGroupReasonCodefilter_data,
  setDepartmentsDashboardLoaders,
  setPayersDashboardLoaders,
  // setPayersFilters,
} = ExecutiveDashKpisSlice.actions;

// get data list
export const gettingExecutivePayerDashboad_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "executivePayersDashboardFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceState =
      getState().npReducers.filters.executivePayersDashboardFilter;

    try {
      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      };
      let params = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        ExecutivePayerData,
        "executivePayersDashboardFilter",
        "GET_EXECUTIVE_DASH_PAYER_KPIS",
        null
      );
    } catch (e) {
      //   console.log("e--->>", e);
    }
  };

// get data list
export const gettingPayerDashboad_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    dispatch(setPayersDashboardLoaders(true));

    if (clear) {
      dispatch(
        resetListingState({
          listing: "payerDashboardFilter",
          ignore: ["claimStatus,payers,cptCodes,CARC"],
        })
      );

      // ========= below code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - start ==============
      let advStngServiceLineExecList =
        getState().pReducers.user.advStngServiceLineExecList;
      let data = advStngServiceLineExecList?.split(",")?.map((item) => {
        return {
          label: item.toString(),
          value: item.toString(),
        };
      });
      dispatch(
        setListingState({
          listing: "payerDashboardFilter",
          value: {
            smartFilterArr: data,
            pageNumber: 1,
          },
        })
      );
      // ========= above code used for reset button for don't remove "smartFilter" from api while reseting button is hitting - end ==============
    }

    // dispatch(gettingSmartFilterForOncologyDenial_Call("payerDashboardFilter"));
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.payerDashboardFilter;
    let sliceStateForSmartFilters =
      getState().npReducers.filters.payerDashboardFilter;
    // console.log(">> smart filters ", sliceStateForSmartFilters?.smartFilter);
    try {
      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdValue =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId || getCurrentClientId();

            // Check if clientIdValue is not an empty string, NaN, or null
            return clientIdValue &&
              clientIdValue !== "" &&
              !isNaN(clientIdValue)
              ? clientIdValue
              : undefined;
          })(),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && { from: sliceStateForDate.from }),
        ...(sliceStateForDate?.to !== "" && { to: sliceStateForDate.to }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState === "Months" ? "W" : "Q",
        }),
        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdValue =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId || getCurrentClientId();

            return clientIdValue &&
              clientIdValue !== "" &&
              !isNaN(clientIdValue)
              ? clientIdValue
              : undefined;
          })(),
        }),
      };
      let paramsPayerDash = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "M" : "Y",
        }),

        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),

        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: (() => {
            const clientIdValue =
              Array.isArray(sliceStateForDate?.clientId) &&
              sliceStateForDate?.clientId.length > 0
                ? sliceStateForDate.clientId[0].value
                : sliceStateForDate?.clientId || getCurrentClientId();

            // Check if clientIdValue is not an empty string, NaN, or null
            return clientIdValue &&
              clientIdValue !== "" &&
              !isNaN(clientIdValue)
              ? clientIdValue
              : undefined;
          })(),
        }),
      };

      // Remove clientId if it is undefined
      if (paramsTemp.clientId === undefined) {
        delete paramsTemp.clientId;
      }
      // Remove clientId if it is undefined
      if (params.clientId === undefined) {
        delete params.clientId;
      }
      // Remove clientId if it is undefined
      if (paramsPayerDash.clientId === undefined) {
        delete paramsPayerDash.clientId;
      }

      // console.log(
      //   "params--->>",
      //   params,
      //   "sliceStateForDate?.clientId",
      //   sliceStateForDate?.clientId,
      //   getCurrentClientId()
      // );

      // 2nf function  ----- start
      // get data denialTimeLine by payer

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDenialByPayerValues,
        "payerDashboardFilter2",
        "GET_DENIALS_DASH_KPIS",
        null
      );
      // 2nf function  ----- end

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      commonPagination(
        getState,
        paramsTemp,
        paramsPayerDash,
        dispatch,
        setPayerDashNewTopCards,
        "payerDashboardTopCardsFilter",
        "GET_EXECUTIVE_DASH_PAYER_TOP_CARDS",
        null
      );
      commonPagination(
        getState,
        paramsTemp,
        paramsPayerDash,
        dispatch,
        setPayerDashTopFive,
        "payerDashboardFilter",
        "GET_EXECUTIVE_DASH_PAYER_TOP_FIVE",
        null
      );
      commonPagination(
        getState,
        paramsTemp,
        paramsPayerDash,
        dispatch,
        setPayerDashTopTen,
        "payerDashboardTopTenFilter",
        "GET_EXECUTIVE_DASH_PAYER_TOP_TEN",
        null
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// reasonCode Trends by payer dashboard api
export const gettingReasonCodeTrendsDash_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "reasonCodeTrendsbyPayerFilters",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState =
      getState().npReducers.filters.reasonCodeTrendsbyPayerFilters;

    let Start = sliceState.pageNumber;
    let Limit = sliceState.pageSize;

    if (Start >= 2) {
      let rec_val = (Start - 1) * Limit;
      Start = rec_val;
    }
    if (Start == 1) {
      Start = 0;
    }

    try {
      ///// API CALL //////

      // =======================
      let paramsTemp = {
        from: sliceStateForDate.from,
        to: sliceStateForDate.to,
        ...(sliceStateForDate.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState === "Months" ? "1M" : "1Y",
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueIntoString(
              sliceState?.smartFilterArr,
              "value"
            ),
          }),

        ...(sliceState?.payers?.length > 0 && {
          payerId: getMultiSelectFilterValueIntoString(
            sliceState?.payers,
            "value"
          ),
        }),
        ...(sliceState?.payers?.length > 0 && {
          payerName: getMultiSelectFilterValueIntoString(
            sliceState?.payers,
            "label"
          ),
        }),
        ...(sliceState?.groupReasonCodes?.length > 0 && {
          groupCARC: getMultiSelectFilterValueIntoString(
            sliceState?.groupReasonCodes,
            "value"
          ),
        }),

        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        from: sliceStateForDate.from,
        to: sliceStateForDate.to,
        ...(sliceStateForDate.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState === "Months" ? "1M" : "1Y",
        }),
        ...(sliceState?.smartFilter &&
          sliceState?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueIntoString(
              sliceState?.smartFilterArr,
              "value"
            ),
          }),

        ...(sliceState?.payers?.length > 0 && {
          payerId: getMultiSelectFilterValueIntoString(
            sliceState?.payers,
            "value"
          ),
        }),
        ...(sliceState?.payers?.length > 0 && {
          payerName: getMultiSelectFilterValueIntoString(
            sliceState?.payers,
            "label"
          ),
        }),
        ...(sliceState?.groupReasonCodes?.length > 0 && {
          groupCARC: getMultiSelectFilterValueIntoString(
            sliceState?.groupReasonCodes,
            "value"
          ),
        }),

        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      constDate = sliceStateForDate.dateMainState === "Months" ? "1M" : "1Y";

      // console.log("params--->>", params);

      //=====================
      let sliceState12 =
        getState().npReducers.filters["reasonCodeTrendsbyPayerFilters"];
      let cacheData = { ...sliceState12.cachePagination };
      let cachParamsState = { ...sliceState12.cacheParams };
      let cache = cacheData[`page_${sliceState12.pageNumber}`];

      if (
        cache &&
        // cache.claims.length > 0 &&
        JSON.stringify(cachParamsState) === JSON.stringify(paramsTemp)
      ) {
        // dispatch(stateValueSetter(cache));
        // return;
      } else {
        // call for smart filters
        dispatch(
          gettingSmartFilterForOncologyDenial_Call(
            "reasonCodeTrendsbyPayerFilters"
          )
        );

        // filters data api call
        dispatch(gettingPayerfilter_data());
        dispatch(gettingGroupReasonCodefilter_data());

        // graph data api call
        dispatch(gettingLineChart());
        commonPaginationForPost(
          getState,
          paramsTemp,
          params,
          dispatch,
          setLineChartData,
          "reasonCodeTrendsbyPayerFilters",
          "GET_REASON_CODE_TRENDS_BY_PAYER_GRAPH_DATA_API",
          null
        );
      }
      //=====================

      ////// API CALL END //////////////

      return 0;
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// get data list
export const gettingDepartmentDashboad_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    dispatch(setDepartmentsDashboardLoaders(true));

    if (clear) {
      dispatch(
        resetListingState({
          listing: "departmentDashboardFilter",
          ignore: ["claimStatus,payers,cptCodes,CARC"],
        })
      );
    }
    // dispatch(
    //   gettingSmartFilterForOncologyDenial_Call("departmentDashboardFilter")
    // );
    let advStngServiceLineExecList =
      getState().pReducers.user.advStngServiceLineExecList;
    let data = advStngServiceLineExecList?.split(",")?.map((item) => {
      return {
        label: item.toString(),
        value: item.toString(),
      };
    });

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.departmentDashboardFilter;
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForSmartFilters =
      getState().npReducers.filters.departmentDashboardFilter;

    try {
      let paramsTemp = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filterBy: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let params = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filter: sliceStateForDate.dateMainState == "Months" ? "W" : "Q",
        }),

        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };

      let paramsPayerDash = {
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateForDate?.dateMainState !== "" && {
          filterBy: sliceStateForDate.dateMainState == "Months" ? "M" : "Y",
        }),

        ...(sliceState?.payers?.length > 0 && {
          PayerId: getFilterValuesByKey(sliceState?.payers, "value"),
        }),
        ...(sliceStateForSmartFilters?.smartFilter &&
          sliceStateForSmartFilters?.smartFilterArr?.length > 0 && {
            SmartFilter: getMultiSelectFilterValueForApi(
              sliceStateForSmartFilters?.smartFilterArr
            ),
          }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? String(sliceStateForDate?.clientId[0].value)
              : String(sliceStateForDate?.clientId || getCurrentClientId()),
        }),
      };
      const payerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
          paramsPayerDash = { ...paramsPayerDash, ...treeSelected };
        }
      }

      // 2nf function  ----- start
      // get data denialTimeLine by payer

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      //     GET_TOP_TEN_DENIALS_DEPARTMENTS
      // GET_TOP_CARD_DEPARTMENTS
      // GET_TOP_FIVE_DEPARTMENTS

      commonPagination(
        getState,
        paramsTemp,
        paramsPayerDash,
        dispatch,
        setDepartmentDashNewTopCards,
        "departmentDashboardFilterTopCards",
        "GET_TOP_CARD_DEPARTMENTS",
        null
      );
      // 2nf function  ----- end

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)

      // commonPagination(
      //   getState,
      //   paramsTemp,
      //   paramsPayerDash,
      //   dispatch,
      //   setDepartmentDashNewTopCards,
      //   "departmentDashboardFilter",
      //   "GET_TOP_CARD_DEPARTMENTS",
      //   null
      // );
      commonPagination(
        getState,
        paramsTemp,
        paramsPayerDash,
        dispatch,
        setDepartmentDashTopFive,
        "departmentDashboardFilterTopFive",
        "GET_TOP_FIVE_DEPARTMENTS",
        null
      );

      commonPagination(
        getState,
        paramsTemp,
        paramsPayerDash,
        dispatch,
        setDepartmnetDashTopTen,
        "departmentDashboardFilterTopTen",
        "GET_TOP_TEN_DENIALS_DEPARTMENTS",
        null
      );

      // filters api call

      dispatch(
        getClaimAllFiltersData_Call(
          {},
          payerParams,
          {},
          {},
          {},
          {},
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isClaimStatusAPI: false,
            isReasonCodeAPI: false,
            isProviderAPI: false,
            isFillingIndicatorAPI: false,
            isBillingProviderAPI: false,
            isRenderingProviderAPI: false,
            isIPOPRemartCodeAPI: false,
            isCptCodeAPI: false,
            isGroupCodeAPI: false,
          }
        )
      );
    } catch (e) {
      console.log("e--->>", e);
    } finally {
      dispatch(
        setListingState({
          listing: "departmentDashboardFilter",
          value: {
            loadingMore: "",
          },
        })
      );
    }
  };

export default ExecutiveDashKpisSlice.reducer;
