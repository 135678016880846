import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import browserRoute from "../../../constants/browserRoutes";
import SideBarBot from "./SidebarBot";
import SideBar from "./Sidebar";

export const SidebarMain = () => {
  const botRoutes = [
    browserRoute?.CHAT_BOT_RESULT,
    browserRoute?.CHAT_BOT_RESULT,
    browserRoute?.ASK_ZOE,
    browserRoute?.ASK_ROVERS,
    browserRoute?.DOMAIN_COPILOT,
    browserRoute?.ASK_CUSTOM,
    browserRoute?.UPLOAD_DOCOMENTS,
    browserRoute?.ASK_ZOE_CHAT,
  ];
  const location = useLocation();
  const [showBotRoute, setShowBotRoute] = useState(false);
  useEffect(() => {
    if (botRoutes?.includes(location.pathname)) {
      setShowBotRoute(true);
    } else {
      setShowBotRoute(false);
    }
  }, [location.pathname]);
  return <div>{showBotRoute ? <SideBarBot /> : <SideBar />}</div>;
};
