import { createSlice } from "@reduxjs/toolkit";
// import moment from "moment";

import {
  // setListingState,
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  commonPaginationWithParams,
  getCurrentClientId,
  // getDataForLineChart,
} from "../../../components/common/Utils/utils";
// import { getMultiSelectFilterValueForApi } from "../../../components/common/Utils/utils";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";
// import axios from "../../../AxiosConfig/axios";
import { toast } from "react-toastify";
// import browserRoute from "../../../Constants/browserRoutes";
// import { getNextWeekMonQuarterlyDate } from "../../../components/common/Utils/utils";
import { getRandomString } from "./../../../components/common/Utils/utils";

export const HealthSystemSlice = createSlice({
  name: "HealthSystemSlice",
  initialState: {
    //Filter Array

    healthSystemTabularData: { tabularArr: [], totalCount: 0 },
    healthSystemPayeeArr: [],

    loading: false,
    addingNew: false,
    deleteItemLoading: false,
    gettingNPIList: false,

    //Buckets
    bucketsList: [],
    bucketsLoader: true,
    bucketsCount: 0,
    pageSize: 10,

    page: 1,
    limit: 10,
    bucketForceFlag: false,

    bucketsAgainstRole: [],

    // queues screen
    queuesAgainstBuckets: {},
    queuesCount: 0,

    queuePageSize: 10,
    queuePage: 1,
    queueLimit: 10,
    queueForceFlag: false,

    deleteQueueLoader: false,
  },
  reducers: {
    gettingData: (state, action) => {
      state.loading = true;
    },
    setDeleteItemLoading: (state, action) => {
      state.deleteItemLoading = action.payload;
    },
    gettingNPIList: (state, action) => {
      state.gettingNPIList = true;
    },
    addingNewHealthSystem_Action: (state, action) => {
      state.addingNew = action.payload;
    },
    setHealthSystemTabularData_Action: (state, action) => {
      let data = action.payload;

      if (data?.healthCareModels?.length) {
        state.healthSystemTabularData.tabularArr = data?.healthCareModels;
        state.healthSystemTabularData.totalCount =
          data?.healthCareModels[0]?.totalCount;
      } else {
        state.healthSystemTabularData.tabularArr = [];
        state.healthSystemTabularData.totalCount = 0;
      }

      state.loading = false;
    },
    setHealthSystemPayeeListData_Action: (state, action) => {
      let data = action.payload;

      if (data.length > 0) {
        let list = data?.map((item) => {
          return {
            value: `${item?.payeeName}-${item?.npiNumber}`,
            label: `${item?.payeeName}-${item?.npiNumber}`,
            npiNumber: item?.npiNumber,
            payeeId: item?.id,
          };
        });

        state.healthSystemPayeeArr = list;
        state.gettingNPIList = false;
      } else {
        state.healthSystemPayeeArr = [];
        state.gettingNPIList = false;
      }
    },

    setBucketsList: (state, action) => {
      state.bucketsList = action?.payload?.queueBuckets;
      state.bucketsCount = action?.payload?.count;
      state.bucketsLoader = false;
    },

    setBucketsLoader: (state, action) => {
      state.bucketsLoader = action?.payload;
    },
    setBucketsAgainstRole: (state, action) => {
      state.bucketsAgainstRole = action?.payload;
      // state.bucketsLoader = false;
    },
    setQueuesAgainstBucket: (state, action) => {
      state.queuesCount = action?.payload[0]?.countRecords;
      state.queuesAgainstBuckets = action?.payload;

      // const data = action?.payload?.data;
      // const bucketId = action?.payload?.params?.bucketId;

      // if (state.queuesAgainstBuckets[`${[`${data[0]?.bucketId}`]}`]) {
      //   state.queuesAgainstBuckets = state.queuesAgainstBuckets?.filter(
      //     (item) => item == [`${[`${data[0]?.bucketId}`]}`]
      //   );
      // }
      // if (data && data?.length > 0) {
      //   state.queuesAgainstBuckets[`${[`${data[0]?.bucketId}`]}`] = data;
      //   state.queuesCount[`${data[0].bucketId}`] = data[0]?.countRecords;
      // } else {
      //   state.queuesAgainstBuckets[`${[`${bucketId}`]}`] = [];
      // }

      // state.queuesCount = Object.values(action?.payload).filter(
      //   (item) => item?.length > 0
      // )?.[0]?.[0]?.countRecords;
      // state.bucketsLoader = false;
    },
    setQueuesCount: (state, action) => {
      // state.queuesCount = action?.payload;
      // state.bucketsLoader = false;
    },
    setPageRows: (state, action) => {
      state.pageSize = action?.payload;
      // state.bucketsLoader = false;
    },
    setPage: (state, action) => {
      state.page = action?.payload;
      // state.bucketsLoader = false;
    },
    setQueuePage: (state, action) => {
      state.queuePage = action?.payload;
      // state.bucketsLoader = false;
    },
    setQueuePageRows: (state, action) => {
      state.queuePageSize = action?.payload;
      // state.bucketsLoader = false;
    },
    setLimit: (state, action) => {
      state.limit = action?.payload;
      // state.bucketsLoader = false;
    },
    setQueuesLimit: (state, action) => {
      state.queueLimit = action?.payload;
      // state.bucketsLoader = false;
    },
    toggleBucketForceFlag: (state, action) => {
      state.bucketForceFlag = action.payload;
      // state.bucketsLoader = false;
    },

    toggleQueueForceFlag: (state, action) => {
      state.queueForceFlag = action.payload;
      // state.bucketsLoader = false;
    },
    toggleDeleteQueueLoader: (state, action) => {
      state.deleteQueueLoader = action.payload;
      // state.bucketsLoader = false;
    },
  },
});

export const {
  setHealthSystemTabularData_Action,
  setHealthSystemPayeeListData_Action,
  gettingData,
  gettingNPIList,
  addingNewHealthSystem_Action,
  setDeleteItemLoading,
  setBucketsList,
  setBucketsLoader,
  setBucketsAgainstRole,
  setQueuesAgainstBucket,
  setQueuesCount,
  setPageRows,
  setPage,
  setLimit,
  toggleBucketForceFlag,
  setQueuePageRows,
  setQueuePage,
  toggleQueueForceFlag,
  toggleDeleteQueueLoader,
  setQueuesLimit,
} = HealthSystemSlice.actions;

// get all health system list
export const gettingHealthSystemTabularData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "healthSystemFilter",
          ignore: ["claimStatus,payers,frequencyType,search"],
        })
      );
    }
    dispatch(gettingData());

    let sliceState = getState().npReducers.filters.healthSystemFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };
      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId: getCurrentClientId(),
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setHealthSystemTabularData_Action,
        "healthSystemFilter",
        "GET_HEALTH_SYSTEM_DATA",
        null
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// get all health system list
export const gettingAllNPIListData_Call = () => async (dispatch, getState) => {
  dispatch(gettingNPIList());

  try {
    genericAxiosCall(
      serverRoutes?.GET_HEALTH_SYSTEM_ALL_PAYEE_LIST_DATA,
      "get",
      "",
      ""
    ).then((res) => {
      dispatch(setHealthSystemPayeeListData_Action(res?.data?.data));
    });
  } catch (error) {
    //   console.log("error--->>", error);
    dispatch(setHealthSystemPayeeListData_Action([]));

    toast.error(
      error.response.data.message
        ? error.response.data.message
        : "Something went wrong",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  }
};

// adding new health system
export const addingNewHealthSystem_Call =
  (obj, setInputs, setDeleteObj, setOpenModal) =>
  async (dispatch, getState) => {
    dispatch(addingNewHealthSystem_Action(true));

    try {
      genericAxiosCall(`${serverRoutes.ADD_NEW_HEALTH_SYSTEM}`, "post", obj, "")
        .then((res) => {
          // toast.success("Added Successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          // });

          if (res?.data?.success) {
            toast.success(
              res?.data?.message ? res?.data?.message : "Something went wrong",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          } else {
            toast.error(
              res?.data?.message ? res?.data?.message : "Something went wrong",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          }

          dispatch(
            setListingState({
              listing: "healthSystemFilter",
              value: {
                fetchLatest: true,
              },
            })
          );
          dispatch(gettingHealthSystemTabularData_Call());
          return res?.data;
        })
        .catch((error) => {
          toast.error(
            res?.data?.message ? res?.data?.message : "Something went wrong",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          // toast.error(
          //   error.response.data.message
          //     ? error.response.data.message
          //     : "Something went wrong",
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );

          dispatch(
            setListingState({
              listing: "healthSystemFilter",
              value: {
                fetchLatest: true,
              },
            })
          );
          dispatch(gettingHealthSystemTabularData_Call());
        });
    } catch (e) {
      // console.log("e--->>", e);
    } finally {
      dispatch(addingNewHealthSystem_Action(false));
      setInputs({
        name: "",
        shortName: "",
        location: "",
        npiTags: [],
        visible: false,
      });
      setOpenModal(false);
      setDeleteObj({});
    }
  };

// update item health system
export const updateItemHealthSystem_Call =
  (obj, setInputs, setDeleteObj, setOpenModal) =>
  async (dispatch, getState) => {
    // dispatch(addingNewHealthSystem_Action(true));

    try {
      genericAxiosCall(`${serverRoutes.ADD_NEW_HEALTH_SYSTEM}`, "put", obj, "")
        .then((res) => {
          // toast.success("Updated Successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          if (res?.data?.success) {
            toast.success(
              res?.data?.message ? res?.data?.message : "Something went wrong",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          } else {
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          }

          dispatch(
            setListingState({
              listing: "healthSystemFilter",
              value: {
                fetchLatest: true,
              },
            })
          );
          dispatch(gettingHealthSystemTabularData_Call());
          return res?.data;
        })
        .catch((error) => {
          // toast.error(
          //   error.response.data.message
          //     ? error.response.data.message
          //     : "Something went wrong",
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );
          toast.error(
            res?.data?.message ? res?.data?.message : "Something went wrong",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    } catch (e) {
      // console.log("e--->>", e);
    } finally {
      setInputs({
        name: "",
        shortName: "",
        location: "",
        npiTags: [],
        visible: false,
      });
      setOpenModal(false);
      setDeleteObj({});
    }
  };

// delete item from health system
export const deleteItemHealthSystem_Call =
  (id, setDeleteObj, setOpenDeleteModal, setIsUpdate) =>
  async (dispatch, getState) => {
    try {
      setDeleteItemLoading(true);
      genericAxiosCall(
        `${serverRoutes.ADD_NEW_HEALTH_SYSTEM}/${id}`,
        "delete",
        "",
        ""
      )
        .then((res) => {
          // toast.success("Deleted Successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          // });

          if (res?.data?.success) {
            toast.success(
              res?.data?.message ? res?.data?.message : "Something went wrong",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          } else {
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          }

          dispatch(
            setListingState({
              listing: "healthSystemFilter",
              value: {
                fetchLatest: true,
              },
            })
          );
          dispatch(gettingHealthSystemTabularData_Call());
          return res;
        })
        .catch((error) => {
          // toast.error(
          //   error.response.data.message
          //     ? error.response.data.message
          //     : "Something went wrong",
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );

          toast.error(
            res?.data?.message ? res?.data?.message : "Something went wrong",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    } catch (e) {
      // console.log("e--->>", e);
    } finally {
      setDeleteObj({});
      setOpenDeleteModal(false);
      setDeleteItemLoading(false);
      setIsUpdate(false);
    }
  };

// get all buckets
export const gettingBucketsList_Call = () => async (dispatch, getState) => {
  dispatch(setBucketsLoader(true));
  // dispatch(gettingNPIList());
  const params = {
    time: new Date().getTime(),
    start: 0,
    limit: 10,
    orderBy: "",
    order: "",
  };

  try {
    genericAxiosCall(serverRoutes?.GET_ALL_BUCKETS, "get", "", params).then(
      (res) => {
        dispatch(setBucketsList(res?.data?.data));
      }
    );
  } catch (error) {
    // console.log("error--->>", error);
    dispatch(setBucketsList([]));

    toast.error(
      error.response.data.message
        ? error.response.data.message
        : "Something went wrong",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  }
};

// getBuckets REACT QUERY
export const gettingBucketsPagination_Call =
  (page, limit, f) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.workQueueBucketsFilter;

    dispatch(setBucketsLoader(true));
    const start = page == 0 ? 0 : (page - 1) * limit;
    // const end = page * limit;

    const params = {
      time: f,
      start: start == 1 ? 0 : start,
      limit: limit,
      orderBy: sliceState?.orderBy,
      order: sliceState?.order,
    };

    const res = await genericAxiosCall(
      serverRoutes?.GET_ALL_BUCKETS,
      "get",
      "",
      params
    );

    if (res?.data?.success == true) {
      dispatch(setBucketsList(res?.data?.data));
      return res?.data?.data;
    }
    // dispatch(setBucketsLoader(false));
  };

// get all buckets against role id
export const gettingBucketsListAgainstRole_Call =
  (obj) => async (dispatch, getState) => {
    dispatch(setBucketsLoader(true));
    // dispatch(gettingNPIList());
    // const params = {
    //   time: new Date().getTime(),
    //   start: 0,
    //   limit: 10,
    //   orderBy: "",
    //   order: "",
    // };

    try {
      genericAxiosCall(
        `${serverRoutes?.GET_ALL_BUCKETS_AGAINST_ROLE_ID}${obj}`,
        "get",
        "",
        ""
      ).then((res) => {
        const data = res?.data?.data?.filter((item) => item.isVisible === true);

        const bucketsWithoutDefault = data?.filter(
          (item) => !item?.bucketName?.includes("Default")
        );
        const bucketsWithDefault = data?.filter((item) =>
          item?.bucketName?.includes("Default")
        );
        let newArr = [];
        if (
          bucketsWithDefault?.filter((item) =>
            item?.bucketName?.includes("Analyst")
          )?.[0]
        ) {
          newArr?.push(
            bucketsWithDefault?.filter((item) =>
              item?.bucketName?.includes("Analyst")
            )?.[0]
          );
        }
        if (
          bucketsWithDefault?.filter((item) =>
            item?.bucketName?.includes("Supervisor")
          )?.[0]
        ) {
          newArr?.push(
            bucketsWithDefault?.filter((item) =>
              item?.bucketName?.includes("Supervisor")
            )?.[0]
          );
        }
        if (
          bucketsWithDefault?.filter((item) =>
            item?.bucketName?.includes("Coding")
          )?.[0]
        ) {
          newArr?.push(
            bucketsWithDefault?.filter((item) =>
              item?.bucketName?.includes("Coding")
            )?.[0]
          );
        }

        const finalData = [...newArr, ...bucketsWithoutDefault];

        dispatch(setBucketsAgainstRole(finalData));
        dispatch(setBucketsLoader(false));
      });
    } catch (error) {
      // console.log("error--->>", error);
      dispatch(setBucketsLoader(false));

      dispatch(setBucketsAgainstRole([]));

      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Something went wrong",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };

// delete a bucket
export const deleteBucket_Call =
  (
    id,
    setDeleteObj,
    setOpenDeleteModal,
    setIsUpdate,
    setSelectedCheckboxes,
    deletedBy,
    bucketsList
  ) =>
  async (dispatch, getState) => {
    try {
      setDeleteItemLoading(true);
      const params = {
        bucketId: id,
        deletedBy: deletedBy,
      };
      genericAxiosCall(`${serverRoutes.DELETE_BUCKET}?`, "delete", "", params)
        .then((res) => {
          toast.success("Queue Bucket Deleted Successfully");
          dispatch(toggleBucketForceFlag(Date.now()));
          if (bucketsList?.length > 1) {
            // don't set pageNumber 1
          } else {
            // set pageNumber 1
            dispatch(setPage(1));
          }

          return res;
        })
        .catch((error) => {
          toast.error(
            error.response.data.message
              ? error.response.data.message
              : "Something went wrong",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    } catch (e) {
      // console.log("e--->>", e);
    } finally {
      setDeleteObj({});
      setOpenDeleteModal(false);
      setDeleteItemLoading(false);
      setIsUpdate(false);
      setSelectedCheckboxes("");
    }
  };

// // update bucket
export const updateBucket_Call =
  (
    obj,
    setInputs,
    setDeleteObj,
    setOpenModal,
    setSelectedCheckboxes,
    resetSelections,
    setSelectedRoles,
    setIsLoading
  ) =>
  async (dispatch) => {
    try {
      setIsLoading(true);
      const res = await genericAxiosCall(
        `${serverRoutes.EDIT_BUCKET}`,
        "put",
        obj,
        ""
      );
      dispatch(toggleBucketForceFlag(Date.now()));
      toast.dismiss();
      toast.success(res?.data?.message || "Success", {});
      setInputs({
        name: "",
        shortName: "",
        location: "",
        roles: [],
        visible: true,
      });
      setSelectedRoles([]);
      setOpenModal(false);
      setDeleteObj({});
      setSelectedCheckboxes([]);
      resetSelections();
      return res?.data;
    } catch (error) {
      toast.dismiss();
      toast.error(error.response?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setIsLoading(false); // Ensure this is always called
    }
  };

// // create new bucket
export const createNewBucket_Call =
  (
    obj,
    setInputs,
    setDeleteObj,
    setOpenModal,
    resetSelections,
    setSelectedRoles,
    setIsLoading
  ) =>
  async (dispatch) => {
    try {
      setIsLoading(true);
      const res = await genericAxiosCall(
        `${serverRoutes.CREATE_NEW_BUCKET}`,
        "post",
        obj,
        ""
      );
      dispatch(toggleBucketForceFlag(Date.now()));
      toast.dismiss();
      toast.success(res?.data?.message || "Success", {});
      setInputs({
        name: "",
        shortName: "",
        location: "",
        roles: [],
        visible: true,
      });
      setSelectedRoles([]);
      setOpenModal(false);
      setDeleteObj({});
      resetSelections();
      return res?.data;
    } catch (error) {
      toast.dismiss();
      toast.error(error.response?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setIsLoading(false); // Ensure this is always called
    }
  };

// get all queues against bucket id
export const gettingQueuesAgainstBucketId_Call =
  (obj, queuesAgainstBuckets) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.workQueueFilter;

    let start = sliceState?.pageNumber;
    let limit = sliceState?.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    const params = {
      time: new Date().getTime(),
      start,
      limit,
      bucketId: obj?.bucketId,
    };

    try {
      genericAxiosCall(
        serverRoutes?.GET_ALL_QUEUES_AGAINST_BUCKET_ID,
        "get",
        "",
        params
      ).then((res) => {
        let data = { ...queuesAgainstBuckets };
        data[`${params?.bucketId}`] = res?.data?.data;
        dispatch(setQueuesAgainstBucket(data));
      });
    } catch (error) {
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Something went wrong",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };

// getQueues REACT QUERY
export const gettingQueuesPagination_Call =
  (queuePage, queueLimit, bucketId, f, orderBy, order) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.workQueueFilter;
    let userId = getState().pReducers?.user?.loginUserData?.userId;

    let start = queuePage;
    let limit = queueLimit;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      const params = {
        ...(order !== "" && {
          Order: order,
        }),
        ...(orderBy !== "" && {
          OrderBy: orderBy,
        }),
        ...{
          type:
            sliceState?.groupByFilter === "ALL"
              ? ""
              : sliceState?.groupByFilter === "Assigned to Me"
              ? ""
              : sliceState?.groupByFilter,
        },
        ...(sliceState?.groupByFilter === "Assigned to Me" && {
          assigneeUserId: userId,
        }),
        time: f,
        start: start == 1 ? 0 : start,
        limit: limit,
        bucketId: bucketId,
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_QUEUES_AGAINST_BUCKET_ID,
        "get",
        "",
        params
      );

      if (res?.data?.success == true) {
        dispatch(setQueuesAgainstBucket(res?.data?.data));
        return res?.data?.data;
      }
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// delete single queue
export const deleteQueue_Call =
  (
    id,
    setOpenDeleteModal,
    setSelectedCheckboxes,
    setAppealsPage,
    queryDataArr
  ) =>
  async (dispatch, getState) => {
    try {
      const params = {
        WorkQueueId: id,
      };
      genericAxiosCall(`${serverRoutes.DELETE_QUEUE}`, "delete", "", params)
        .then(async (res) => {
          try {
            if (res?.data?.success === true) {
              toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_CENTER,
              });

              // if (queryDataArr) {
              //   queryDataArr?.length === 1 ? dispatch(setQueuePage(1)) : null;
              // }
              // dispatch(setQueuePage(1));

              if (queryDataArr?.length === 1) {
                // Dispatch pageNumber as 1 using sliceState.pageNumber
                await dispatch(setQueuePage(1));
              }

              setAppealsPage && setAppealsPage(1);

              dispatch(toggleQueueForceFlag(Date.now()));
              return res;
            }
          } catch (err) {
            // console.log("adasdf--->>", err);
          }
        })
        .catch((error) => {
          // console.log("e0--->>", error);
          toast.dismiss();
          toast.error(
            error?.response?.data
              ? error.response.data.message || "Something went wrong"
              : "Something went wrong",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        });
    } catch (error) {
      // console.log("e1--->>", error);
    } finally {
      toggleDeleteQueueLoader(false);
      setOpenDeleteModal(false);
      setSelectedCheckboxes("");
    }
  };

export default HealthSystemSlice.reducer;
